import { Box, Button, confirm, Message, Typography } from 'fbm-ui';
import { Stack } from 'fbm-ui/lib/mui';
import LoginAPI from '../../api/login';
import IntviewAPI from '../../api/Intview';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { interviewActions } from './slice';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  InfoIcon,
  MicIcon,
  MicOffIcon,
  VideocamIcon,
  VideocamOffIcon,
} from 'fbm-icons';
import { useParams, useNavigate } from 'react-router-dom';
import useEffectGetDevices from '../Room/useEffectGetDevices';
import { getPermissionTip } from '../../utils/tips';
import { Tooltip } from '@avocadoui/components';
import ToggleButton from '../Room/ToggleButton';
import { COLOR } from '../../utils/const';
import MicPopover from '../Room/MicPopover';
import CameraPopover from '../Room/CameraPopover';
import useRTC from '../Room/useRTC';
import BackTitle from './BackTitle';
import { useTranslation } from 'react-i18next';
import {
  PermissionCheck,
  CHECK_STEP_INIT,
  CHECK_STEP_MIC,
  CHECK_STEP_CAMERA,
} from './PermissionCheck';
import { showErrorMessage } from '../../utils/apiCheck';
import { log } from '@avocadoui/utils';

const PreviewCheck = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useAppDispatch();

  const { leave } = useRTC();

  const ref = useRef(null);
  const [checkStep, setCheckStep] = useState(CHECK_STEP_INIT);
  const [userName, setUserName] = useState('');
  const [isExpire, setExpire] = useState(false);

  const isLocalAudioMuted = useAppSelector(
    (state) => state.room.isLocalAudioMuted
  );
  const isLocalVideoMuted = useAppSelector(
    (state) => state.room.isLocalVideoMuted
  );
  const isLocalAudioPermissionDenied = useAppSelector(
    (state) => state.room.isLocalAudioPermissionDenied
  );
  const isLocalVideoPermissionDenied = useAppSelector(
    (state) => state.room.isLocalVideoPermissionDenied
  );

  const { isInitialed: isDeviceInitialed } = useEffectGetDevices();
  const { interviewInfo, nextProcess } = useAppSelector(
    (state) => state.interview
  );
  const { questionCount, duration } = interviewInfo || {};

  const handleNext = useCallback(() => {
    if (!(nextProcess.aiInterviewRemainNum > 0)) {
      const message = '面试次数已用完';
      log.capture('PreviewCheck_Start', { message, appliationToken: token });
      return Message.error('面试次数已用完');
    }
    log.capture('PreviewCheck_Start', {
      message: '开始面试',
      appliationToken: token,
    });
    dispatch(interviewActions.setPage(1));
  }, [dispatch, nextProcess.aiInterviewRemainNum, token]);

  useEffect(() => {
    log.capture('PreviewCheck_Step', { step: checkStep });
  }, [checkStep]);

  useEffect(() => {
    LoginAPI.getCndidateInfo(token)
      .then(({ name }) => {
        setUserName(name);
      })
      .catch(showErrorMessage);

    LoginAPI.getCndidateNextProcess(token)
      .then((res) => {
        setExpire(res.process.isExpire);
        dispatch(
          interviewActions.setEnterprise({
            name: res.enterpriseName,
            logo:
              res.enterpriseLogo ||
              'https://webcdn.fbmms.cn/assets/aWIh/O9hYdPxzm2kdOll6Svd6_',
          })
        );
        if (!res.process.token || res.process.type !== 16) {
          navigate(`/process/${token}`);
          return Promise.reject(new Error('当前环节不是AI面试'));
        } else {
          dispatch(interviewActions.setNextProcess(res));
          return res.process.token;
        }
      })
      .then((t) => {
        return IntviewAPI.getInterviewInfo(t)
          .then((res) => {
            dispatch(interviewActions.setInterviewInfo(res));
          })
          .then(() =>
            IntviewAPI.getProgress(t).then((res) => {
              dispatch(interviewActions.setProgressInfo(res));
            })
          );
      })
      .catch(showErrorMessage);
  }, [dispatch, navigate, token]);

  useEffect(() => {
    const pusher = ref.current;
    return () => {
      if (pusher) {
        pusher.stopPush();
      }
    };
  }, [ref]);

  useEffect(() => {
    return () => {
      // leave();
    };
  }, [leave]);

  const { name } = nextProcess.position || {};

  return (
    <Box width={860}>
      <Box
        mt={3}
        mb={4}
        sx={{
          ml: -2,
        }}
      >
        <BackTitle title={name} />
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="body2">
            {t('Hi, X~ Welcome to the AI Interview Room.', { name: userName })}
          </Typography>
          <Typography variant="body2" fontWeight={500} my={2}>
            {t('Notice')}
          </Typography>
          <Typography variant="body2" mb={3}>
            {t(
              '1. There are a total of X questions, and the interview will last approximately X minutes.',
              { count: questionCount, duration: (duration / 60).toFixed() }
            )}
            <br />
            {t(
              '2. Camera and microphone permissions need to be enabled throughout the process.'
            )}
            <br />
            {t(
              '3. Make your internet connection stable, maintain good lighting and quiet surroundings.'
            )}
            <br />
            {t(
              '4. Complete the interview independently,Assistance from others will be considered as failure'
            )}
          </Typography>
        </Stack>
        <PermissionCheck step={checkStep} onSuccess={setCheckStep} />
      </Stack>
      <Box mt={3} height={32} display="flex" justifyContent="center">
        {checkStep === CHECK_STEP_INIT && (
          <Button
            sx={{
              width: 208,
            }}
            onClick={() => {
              if (isExpire) {
                return confirm({
                  title: '',
                  footer: ({ onClose }) => {
                    return (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button onClick={onClose}>{t('Ok')}</Button>
                      </Box>
                    );
                  },
                  content: (
                    <Box
                      display="flex"
                      alignItems="center"
                      mt={4}
                      color="rgba(0, 0, 0, 0.56)"
                      fontSize={16}
                    >
                      {t('Expired: Not completed in time.')}
                    </Box>
                  ),
                });
              } else {
                setCheckStep(CHECK_STEP_MIC);
              }
            }}
          >
            {t('Test Devices')}
          </Button>
        )}
        {checkStep === CHECK_STEP_CAMERA && !isLocalVideoPermissionDenied && (
          <Button
            sx={{
              width: 208,
            }}
            onClick={handleNext}
          >
            {t('Next')}
          </Button>
        )}
      </Box>
      <Stack
        sx={{ display: 'none' }}
        flexDirection="row"
        my={2}
        justifyContent="space-between"
        height={32}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <Box sx={{ position: 'relative' }}>
            {isLocalAudioPermissionDenied ? (
              <Tooltip title={getPermissionTip('audio')} placement="top">
                <span>
                  <Box sx={{ position: 'relative' }}>
                    <ToggleButton
                      icon={<MicOffIcon style={{ color: COLOR.DISABLED }} />}
                    >
                      {t('Microphone')}
                      <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                        <InfoIcon sx={{ width: 16, color: '#F5C441' }} />
                      </Box>
                    </ToggleButton>
                  </Box>
                </span>
              </Tooltip>
            ) : (
              <ToggleButton
                icon={
                  isLocalAudioMuted ? (
                    <MicOffIcon style={{ color: COLOR.SECONDARY }} />
                  ) : (
                    <MicIcon style={{ color: COLOR.DEFAULT }} />
                  )
                }
              >
                {t('Microphone')}
              </ToggleButton>
            )}
            {!isLocalAudioMuted && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '-50%',
                  transform: 'translate(-30%, -50%)',
                }}
              >
                <MicPopover />
              </Box>
            )}
          </Box>
          <Box sx={{ position: 'relative' }}>
            {isLocalVideoPermissionDenied ? (
              <Tooltip title={getPermissionTip('video')} placement="top">
                <span>
                  <Box sx={{ position: 'relative' }}>
                    <ToggleButton
                      icon={
                        <VideocamOffIcon style={{ color: COLOR.DISABLED }} />
                      }
                    >
                      {t('Camera')}
                      <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                        <InfoIcon sx={{ width: 16, color: '#F5C441' }} />
                      </Box>
                    </ToggleButton>
                  </Box>
                </span>
              </Tooltip>
            ) : (
              <ToggleButton
                icon={
                  isLocalVideoMuted ? (
                    <VideocamOffIcon style={{ color: COLOR.SECONDARY }} />
                  ) : (
                    <VideocamIcon style={{ color: COLOR.DEFAULT }} />
                  )
                }
              >
                {t('Camera')}
              </ToggleButton>
            )}
            {!isLocalVideoMuted && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '-50%',
                  transform: 'translate(-30%, -50%)',
                }}
              >
                <CameraPopover />
              </Box>
            )}
          </Box>
        </Stack>

        <Button
          onClick={handleNext}
          disabled={
            !isDeviceInitialed ||
            isLocalVideoPermissionDenied ||
            isLocalAudioPermissionDenied
          }
        >
          {t('Next')}
        </Button>
      </Stack>
    </Box>
  );
};

export default PreviewCheck;
