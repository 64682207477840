import { IconButton, Typography } from 'fbm-ui';
import { Box, Paper, Stack } from 'fbm-ui/lib/mui';
import LoginAPI from '../../api/login';
import TypewriterAPI from '../../api/typewriter';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { showErrorMessage } from '../../utils/apiCheck';
import { ArrowLeftIcon, DoneIcon } from 'fbm-icons';

const GROUPS = [[5, 6, 7, 11], [21]];

const Finish = () => {
  const { applicationToken = '' } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);

  const isSimulated = info?.isSimulated;

  const handleGoBack = useCallback(() => {
    if (isSimulated) {
      navigate(`/simulate/record/${applicationToken}`, { replace: true });
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate(`/login/${applicationToken}`, { replace: true });
    }
  }, [applicationToken, navigate, isSimulated]);

  useEffect(() => {
    LoginAPI.getCndidateNextProcess(applicationToken)
      .then(({ isEnd, process, position, nextProcessIsEndUri }) => {
        const group = GROUPS.findIndex((grp) => {
          return grp.includes(process.type);
        });
        if (group === 1)
          return TypewriterAPI.getTypewriterStatus(process.token).then(
            (processResult) => {
              return {
                isEnd,
                process,
                position,
                group,
                processResult,
                nextProcessIsEndUri,
              };
            }
          );
        return Promise.resolve({
          isEnd,
          process,
          position,
          group,
          nextProcessIsEndUri,
          processResult: {},
        });
      })
      .then(
        ({
          isEnd,
          position,
          group,
          process,
          nextProcessIsEndUri,
          processResult = {},
        }) => {
          if (isEnd) {
            if (nextProcessIsEndUri) {
              window.location = nextProcessIsEndUri;
              return;
            }
            if (position.isSimulated) {
              navigate(`/simulate/record/${applicationToken}`, {
                replace: true,
              });
              return;
            }
          }
          setInfo({
            name: position.name,
            isSimulated: position.isSimulated,
            pcCompleteUrl: position.pcCompleteUrl,
            isEnd,
            processName: process.name,
            qrCode: position.qrCode,
            showType: group,
            type: process.type,
            interviewToken: process.interviewToken,
            processResult,
          });
        }
      )
      .catch(showErrorMessage);
  }, [applicationToken, navigate]);

  const Text = {
    '0': `欢迎参加${info?.name}面试，请联系HR询问具体面试安排。`,
    '-1': `欢迎参加${info?.name}面试`,
  };

  if (!info) return null;

  if (info.showType === 1 && info?.processResult?.status !== 3) {
    navigate(`/t/login/${applicationToken}/${info?.interviewToken}`, {
      replace: true,
    });
    return null;
  }

  if (info.type === 16) {
    navigate(`/interview/ai/${applicationToken}`, { replace: true });
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          background: `${
            info.processResult?.bgImg
              ? `url(${info.processResult?.bgImg})`
              : '#F9FAF9'
          } `,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box
          flexShrink={0}
          component={Paper}
          width={1}
          height={60}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
        >
          <Stack display="flex" alignItems="center" direction="row" spacing={1}>
            <IconButton onClick={handleGoBack}>
              <ArrowLeftIcon sx={{ color: 'rgba(0,0,0,0.86)' }} />
            </IconButton>
            <Typography variant="body1" fontWeight="medium">
              {info?.name}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ margin: 'auto' }}>
          {info.isEnd && (
            <Stack justifyContent="center" alignItems="center" spacing={4}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 54,
                  height: 54,
                  borderRadius: '50%',
                  background: 'rgb(7, 193, 96)',
                  color: 'white',
                }}
              >
                <DoneIcon sx={{ fontSize: 48 }} />
              </Box>
              <Typography variant="h6" fontWeight={500}>
                面试已完成，可向HR咨询具体面试结果。
              </Typography>
            </Stack>
          )}

          {!info.isEnd && (
            <Stack
              component={Paper}
              variant="outlined"
              spacing={5}
              justifyContent="center"
              alignItems="center"
              px={4}
              sx={{ width: 480, height: 360 }}
            >
              <Typography
                fontWeight={700}
                width="100%"
                textAlign={`${info.showType === 1 ? 'center' : 'start'}`}
                fontSize={20}
              >
                {info.showType === 1
                  ? info?.processResult?.accuracySlowNotice
                  : Text[`${info.showType}`]}
              </Typography>
              {info.showType === -1 && (
                <>
                  <Box
                    component="img"
                    src={info?.qrCode}
                    alt="qrcode"
                    width={142}
                    height={142}
                    sx={{ background: 'black' }}
                  ></Box>
                  <Typography>微信扫码进入{info?.processName}环节</Typography>
                </>
              )}
            </Stack>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 60,
          width: '100%',
          height: 'calc(100% - 60px)',
          background: `url(${info.pcCompleteUrl}) no-repeat center center`,
          backgroundSize: 'cover',
        }}
      ></Box>
    </>
  );
};

export default Finish;
